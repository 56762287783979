<template>
    <div>
        <b-modal ref="setor-form" size="lg"
                 hide-footer centered fixed scrollable
                 title="Setor">
            <b-container fluid>
                <b-row>
                    <b-col>
                        <input-padrao classes="m-0" label="Nome *" v-model="$v.nome.$model" />
                        <p class="txt-erro-validacao" v-if="clickSalvar && $v.nome.$invalid">Campo nome é obrigátório</p>
                    </b-col>
                    <b-col style="font-size: 14px;">
                        <p class="m-0">Horário de atendimento</p>
                        <label>
                            <small class="d-block font-weight-bold" style="font-size: 14px;">Início</small>
                            <input type="time"
                                   style="background-color: var(--cinza-2); border: 1px solid var(--cinza-4);"
                                   v-model="horarioAtendimentoInicial" />
                        </label>
                        <label class="ml-4">
                            <small class="d-block font-weight-bold" style="font-size: 14px;">Término</small>
                            <input type="time"
                                   style="background-color: var(--cinza-2); border: 1px solid var(--cinza-4);"
                                   v-model="horarioAtendimentoTermino" />
                        </label>
                    </b-col>
                </b-row>

                <b-row class="border mx-1 mt-3 py-2">
                    <b-row class="px-3">
                        <b-col>
                            <input-select label="Habilitar finalização de atendimento" :opcoes="opcoesBooleano" v-model.trim="habilitarFinalizaAtendimento" />
                        </b-col>
                    </b-row>
                    <div v-show="habilitarFinalizaAtendimento">
                        <b-row class="px-3">
                            <b-col>
                                <input-select label="Selecione o periodo" :opcoes="opcoesPeriodos" v-model.trim="periodoSelecionado" />
                            </b-col>
                            <b-col>
                                <div style="font-size:12px; margin-left:10px;">
                                    <label style="font-weight:bold; font-size:12px;">Status</label>
                                    <b-form-checkbox-group id="checkbox-group-2"
                                                           v-model="statusSelecionado">
                                        <b-form-checkbox v-for="item in opcoesStatus" :value="item.value">{{item.text}}</b-form-checkbox>
                                    </b-form-checkbox-group>

                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-row>
                <b-row class="border mx-1 mt-3 py-2">
                    <b-col cols="6">
                        <input-select label="Habilitar SMS" :opcoes="opcoesBooleano" v-model="smsHabilitado" />
                    </b-col>
                    <b-col cols="12" class="px-3" v-show="smsHabilitado">
                        <hr />
                        <h5 class="mt-4">Configurações SMS</h5>
                        <b-row>
                            <b-col cols="6" class="mb-2">
                                <input-select label="Provedor" :opcoes="opcoesProvedoresSMS" v-model="provedorSMS" />
                            </b-col>
                        </b-row>
                        <b-row v-show="provedorSMS == 1">
                            <b-col class="mb-2">
                                <input-padrao classes="m-0" label="Usuário SMS UniPix" v-model.trim="usuarioSMSUniPix" />
                            </b-col>
                            <b-col class="mb-2">
                                <input-padrao classes="m-0" tipo="password" label="Senha SMS UniPix" v-model.trim="senhaSMSUniPix" />
                            </b-col>
                        </b-row>
                    </b-col>

                </b-row>
                <b-row class="border mx-1 mt-3 py-2">
                    <b-col cols="6">
                        <input-select label="Habilitar WhatsApp Web" :opcoes="opcoes" v-model.trim="habilitarWhatsWeb" />
                    </b-col>
                    <div class="px-3" v-show="habilitarWhatsWeb === 'Sim'">
                        <hr />
                        <h5 class="mt-4">Configurações WhatsApp Web</h5>
                        <b-row cols="2">
                            <b-col class="mb-2">
                                <input-padrao classes="m-0" label="Número Whatsapp" v-model.trim="$v.telefoneWhatsWeb.$model" />
                                <p class="txt-erro-validacao" v-if="clickSalvar && $v.telefoneWhatsWeb.$invalid">Número é obrigátório</p>
                            </b-col>
                            <b-col class="mb-2">
                                <input-padrao classes="m-0" label="URL Serviço de Integração" v-model.trim="$v.caminhoWhatsWeb.$model" />
                                <p class="txt-erro-validacao" v-if="clickSalvar && $v.caminhoWhatsWeb.$invalid">URL é obrigátório</p>
                            </b-col>
                        </b-row>
                    </div>

                </b-row>
                <!--Configurações para WhatsBusiness-->
                <b-row class="border mx-1 mt-3 py-2">
                    <b-col cols="6">
                        <h5 class="mt-4">Configurações WhatsApp Business</h5>
                    </b-col>
                    <div class="px-3">
                        <hr />
                        <b-row cols="2">
                            <b-col class="mb-2">
                                <input-padrao classes="m-0" label="App" v-model="appBm" />
                            </b-col>
                            <b-col class="mb-2">
                                <input-padrao classes="m-0" label="AppKey" v-model="appKeyBm" />
                            </b-col>
                            <b-col class="mb-2">
                                <input-padrao classes="m-0" label="Numero" v-model="numeroBm" />
                            </b-col>
                        </b-row>
                    </div>

                </b-row>
            </b-container>
            <div class="w-100 text-right mt-4">
                <botao-modal-cancel class="d-inline mr-2" @click="fecharModal">Cancelar</botao-modal-cancel>
                <botao-modal-ok class="d-inline" @click="salvarSetor">Salvar</botao-modal-ok>
            </div>
        </b-modal>
        <ModalIMAPFormsConfig ref="imap-forms-config" />
        <ModalBloqueioEmail ref="email-bloqueio" :SetorId="id" :EmailsBloquedos="emailsBloquedos" />
        
    </div>
</template>

<script>
    
    
    import { required, email, requiredIf } from "vuelidate/lib/validators";
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue'
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue'
    import InputPadrao from '@/components/inputs/InputPadrao.vue'
    import InputSelect from '@/components/inputs/InputSelect.vue'
    import ModalIMAPFormsConfig from "@/components/common/ModalIMAPFormsConfig";
    import ModalBloqueioEmail from "@/components/setores/ModalBloqueioEmail";
   
    import axios from 'axios'
    export default {
        name: 'UsuarioFormPopup',
        components: {
            InputPadrao,
            InputSelect,
            BotaoModalOk,
            BotaoModalCancel,
            ModalIMAPFormsConfig,
            ModalBloqueioEmail,
            
        },


        data() {
            return {
                id: '',
                empresaId: '',
                nome: '',
                horarioAtendimentoInicial: '',
                horarioAtendimentoTermino: '',
                
                emailSac: '',
                senhaSac: '',
                enderecoImap: '',
                portaEmail: 0,
                atendimentoAutomatico: 'Não',
                exclusaoAutomatica: false,
                remetentesEmailFormulario: null,
                templateEmailFormulario: null,
                emailSmtp: '',
                senhaSmtp: '',
                enderecoSmtp: '',
                portaSmtp: 0,
                habilitarSsl: 'Não',
                habilitarCredenciaisEmail: 'Não',
                habilitarWhatsWeb: 'Não',
                habilitarFinalizaAtendimento: false,
                periodoSelecionado: 0,
                caminhoWhatsWeb: '',
                sessionWhatsWeb: '',
                telefoneWhatsWeb: '',
                selectedSessionKey: 'SHARKDATA',
                opcoes: [{ text: 'Sim', value: 'Sim' }, { text: 'Não', value: 'Não' }],
                opcoesStatus: [
                    { text: 'Ativo', value: 0 },
                    { text: 'Pendente', value: 1 },
                    { text: 'Em Espera', value: 5 },
                    { text: 'Suspenso', value: 6 },
                ],
                opcoesPeriodos: [{ text: '6 horas', value: 6 }, { text: '12 horas', value: 12 }, { text: '24 horas', value: 24 }, { text: '48 horas', value: 48 }, { text: '72 horas', value: 72 }],
                opcoesBooleano: [{ text: "Sim", value: true }, { text: "Não", value: false }],
                options: [{ text: 'SHARKDATA', value: 'SHARKDATA' }],
                opcoesProvedoresSMS: [],
                clickSalvar: false,
                emailsBloquedos: null,
                statusSelecionado: [],
                setorDados: null,
                appBm: '',
                appKeyBm: '',
                numeroBm: '',
                
                picked_email:null,
                isEdit: false,
                provedor: 0,

                smsHabilitado: false,
				provedorSMS: 0,
				usuarioSMSUniPix: null,
				senhaSMSUniPix: null
            }
        },

        validations: {
            nome: { required },
            //Validacoes WhatsApp
            telefoneWhatsWeb: { required: requiredIf(function () { return this.habilitarWhatsWeb === 'Sim' }) },
            caminhoWhatsWeb: { required: requiredIf(function () { return this.habilitarWhatsWeb === 'Sim' }) },
            // caminhoWhatsWeb: { required: requiredIf(function () { return this.habilitarFinalizaAtendimento === 'Sim' }) },
        },
        methods: {
            showModal(setor) {
                this.limparFormulario();
                this.setorDados = setor;
                if (setor.Id) {
                    this.editar(setor);
                    this.isEdit = true;
                }
                this.$refs['setor-form'].show()
            },
            abrirBloqueioEmail() {
                this.$refs["email-bloqueio"]
                    .open(this.emailsBloquedos);
            },
          
            abrirModalImapFormsConfig() {
                this.$refs["imap-forms-config"]
                    .open(this.remetentesEmailFormulario, this.templateEmailFormulario)
                    .then(data => {
                        this.remetentesEmailFormulario = data.remetentes;
                        this.templateEmailFormulario = data.template;
                    });
            },
            editar: function (setor) {
                this.setorDados = setor;
                this.id = setor.Id;
                this.empresaId = setor.EmpresaId;
                this.nome = setor.Nome;
                this.horarioAtendimentoInicial = setor.HorarioAtendimentoInicial;
                this.horarioAtendimentoTermino = setor.HorarioAtendimentoTermino;
                this.remetentesEmailFormulario = setor.RemetentesEmailFormulario;
                this.templateEmailFormulario = setor.TemplateEmailFormulario;
                this.habilitarCredenciaisEmail = setor.HabilitarCredenciaisEmail;
                this.habilitarWhatsWeb = setor.HabilitarWhatsWeb;
                this.habilitarFinalizaAtendimento = setor.ExclusaoAutomaticaAtendimento;
                this.statusSelecionado = setor.AtendimentoStatus;
                this.periodoSelecionado = setor.TempoExclusaoAutomaticaAtendimento;
                this.caminhoWhatsWeb = setor.CaminhoWhatsWeb;
                this.sessionWhatsWeb = setor.SessionWhatsWeb;
                this.telefoneWhatsWeb = setor.TelefoneWhatsWeb;
                this.selectedSessionKey = setor.SessionKeyWhatsWeb;
                this.emailsBloquedos = setor.EmailsBloqueados;
                this.appBm = setor.App;
                this.appKeyBm = setor.AppKey;
                this.numeroBm = setor.NumeroBM;
                this.provedor = setor.Provedor; 
                this.smsHabilitado = setor.SMSHabilitado;
                this.provedorSMS = setor.ProvedorSMS;
                this.usuarioSMSUniPix = setor.UsuarioSMSUniPix;
                this.senhaSMSUniPix = setor.SenhaSMSUniPix;
            },
            fecharModal: function () {
                this.$refs['setor-form'].hide();
                this.limparFormulario();
            },
            limparFormulario: function () {
                this.clickSalvar = false;
                this.isEdit = false;
                this.picked_email = null;
                this.id = '';
                this.empresaId = '';
                this.nome = '';
                this.horarioAtendimentoInicial = '';
                this.horarioAtendimentoTermino = '';
                
                this.remetentesEmailFormulario = null;
                this.templateEmailFormulario = null;
                this.habilitarCredenciaisEmail = 'Não';
                
                
                this.habilitarWhatsWeb = 'Não';
                this.periodoSelecionado = 0;
                this.habilitarFinalizaAtendimento = false;
                this.caminhoWhatsWeb = '';
                this.sessionWhatsWeb = '';
                this.telefoneWhatsWeb = '';
                this.selectedSessionKey = 'SHARKDATA';
                this.emailsBloquedos = '';
                this.statusSelecionado = [];
                this.appBm = '';
                this.appKeyBm = '';
                this.numeroBm = '';

                this.provedor = 0;

                this.smsHabilitado = false;
                this.provedorSMS = 0;
                this.usuarioSMSUniPix = null;
                this.senhaSMSUniPix = null;
            },
            salvarSetor: function () {

                this.clickSalvar = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return
                }
                this.setorDados.Nome = this.nome;
                this.setorDados.HorarioAtendimentoInicial = this.horarioAtendimentoInicial;
                this.setorDados.HorarioAtendimentoTermino = this.horarioAtendimentoTermino;
                this.setorDados.AtendimentoAutomatico = this.atendimentoAutomatico;
                this.setorDados.ExclusaoAutomatica = this.exclusaoAutomatica;
                this.setorDados.TemplateEmailFormulario = this.templateEmailFormulario; 
                this.setorDados.HabilitarWhatsWeb = this.habilitarWhatsWeb;
                this.setorDados.ExclusaoAutomaticaAtendimento = this.habilitarFinalizaAtendimento;
                this.setorDados.TempoExclusaoAutomaticaAtendimento = this.periodoSelecionado;
                this.setorDados.CaminhoWhatsWeb = this.caminhoWhatsWeb;
                this.setorDados.SessionWhatsWeb = this.sessionWhatsWeb;
                this.setorDados.TelefoneWhatsWeb = this.telefoneWhatsWeb;
                this.setorDados.SessionKeyWhatsWeb = this.selectedSessionKey;
                this.setorDados.EmailsBloqueados = this.emailsBloquedos;
                this.setorDados.AtendimentoStatus = this.statusSelecionado;
                this.setorDados.App = this.appBm;
                this.setorDados.AppKey = this.appKeyBm;
                this.setorDados.NumeroBM = this.numeroBm;

                this.setorDados.Provedor = this.provedor;

				this.setorDados.SMSHabilitado = this.smsHabilitado;
				this.setorDados.ProvedorSMS = this.provedorSMS;
				this.setorDados.UsuarioSMSUniPix = this.usuarioSMSUniPix;
				this.setorDados.SenhaSMSUniPix = this.senhaSMSUniPix;
                if (this.id) {
                    axios.put('/api/setor', this.setorDados)
                        .then((response) => {

                            $("#caixa_carregamento").hide();
                            this.atualizaSetor();
                        }, (error) => {
                            console.log(error);
                            alert("Não foi possível salvar as alterações do setor no momento.");
                            $("#caixa_carregamento").hide();
                        });
                    // this.$store.dispatch(`setores/${this.id ? 'editar' : 'adicionar'}`, this.setorDados);
                } else {
                    axios.post('/api/setor', this.setorDados)
                        .then((response) => {
                            this.atualizaSetor();
                            $("#caixa_carregamento").hide();

                        }, (error) => {
							console.log(error);
							alert("Não foi possível criar o setor no momento.");
                            $("#caixa_carregamento").hide();
                        });



                    //this.$store.dispatch(`setores/${this.id ? 'editar' : 'adicionar'}`, this.setorDados);
                }

                this.fecharModal();

            },
            atualizaSetor() {
                this.$emit('atualizar')
            },
            
        },
        created() {
            axios.get("api/setor/provedores-sms").then(response => this.opcoesProvedoresSMS = (response.data ?? []).map(provedor => ({ value: provedor.Value, text: provedor.Description })));
        }
    }
</script>

<style scoped>
    label {
        font-size: 14px;
        margin: 5px 0 2px 0;
    }

    .txt-erro-validacao {
        color: red;
        font-size: 12px;
        margin: 1px 0 0 0;
    }

    .imap-forms-config {
        cursor: pointer;
        opacity: .5;
        transition: all ease-in-out .3s;
    }

        .imap-forms-config:hover {
            opacity: 1;
        }
</style>