<template>
    <div>
        <Breadcrumb titulo="SETOR" :items="itemsBreadcrumb" />

        <div class="setores-page-container-externo-tabela">
            <div class="setores-page-container-interno-tabela">

                <header-funcoes-lista textoBotao="Novo Setor" @click="setSetor" @pesquisar="pesquisarSetor">
                    <b-button class="mx-2 rounded-0 button-hover-padrao" style="height: 35px; font-size: 13px;" variant="outline-secondary" @click="recarregar">Recarregar</b-button>
                </header-funcoes-lista>

                <tabela-setores class="setores-page-tabela app-scroll-custom"
                                :colunas="getColunasSetor"
                                :lista="listagemSetores"
                                :listaUsuarioSetores="listaUsuarioSetores"
                                :listaIdAtendimentos="listaIdAtendimentos"
                                @editar="setSetor"
                                @setConfigEmail="setConfigEmail"
                                @remover="removerSetor" />

                <strong class="contador-setores">{{setores.length}} setores</strong>
            </div>
        </div>

        <setor-form ref="formularioSetor" @atualizar="buscarSetores" />
        <SetorEmailForm ref="formularioEmailSetor"  @atualizar="buscarSetores" />
        
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import axios from 'axios'
    import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
    import HeaderFuncoesLista from '@/components/tabelas/HeaderFuncoesLista.vue'
    import TabelaSetores from '@/components/setores/TabelaSetores.vue'
    import SetorForm from '@/components/setores/SetorFormPopup.vue'
    import SetorEmailForm from '@/components/setores/SetorEmailPadraoPopup.vue'
  
    class Setor {
        constructor(setor) {
            this.Id = setor?.Id;
            this.EmpresaId = setor?.EmpresaId;
            this.Nome = setor?.Nome;
            this.GPTConfiguracaoId = setor?.GPTConfiguracaoId ?? null;
            this.HorarioAtendimentoInicial = setor?.HorarioAtendimentoInicial;
            this.HorarioAtendimentoTermino = setor?.HorarioAtendimentoTermino;
            this.EmailSAC = setor?.EmailSAC;
            this.EmailSMTP = setor?.EmailSMTP;
            this.EderecoImap = setor?.EderecoImap;
            this.EnderecoSMTP = setor?.EnderecoSMTP;
            this.SenhaSAC = setor?.SenhaSAC;
            this.SenhaSMTP = setor?.SenhaSMTP;
            this.PortaSMTP = setor?.PortaSMTP;
            this.PortaEmail = setor?.PortaEmail;
            this.AtendimentoAutomatico = setor?.AtendimentoAutomatico;
            this.ExclusaoAutomatica = setor?.ExclusaoAutomatica;
            this.RemetentesEmailFormulario = setor?.RemetentesEmailFormulario;
            this.TemplateEmailFormulario = setor?.TemplateEmailFormulario;
            this.HabilitarCredenciaisEmail = setor?.HabilitarCredenciaisEmail;
            this.HabilitarEmailSAC = setor?.HabilitarEmailSAC;
            this.HabilitarSSL = setor?.HabilitarSSL;
            this.HabilitarWhatsWeb = setor?.HabilitarWhatsWeb;
            this.ExclusaoAutomaticaAtendimento = setor?.ExclusaoAutomaticaAtendimento;
            this.TempoExclusaoAutomaticaAtendimento = setor?.TempoExclusaoAutomaticaAtendimento;
            this.EmailsBloqueados = setor?.EmailsBloqueados;
            this.AtendimentoStatus = setor?.AtendimentoStatus;
            this.Alteracao = setor?.Alteracao;
            this.Inclusao = setor?.Inclusao;
            this.CaminhoWhatsWeb = setor?.CaminhoWhatsWeb;
            this.sessionWhatsWeb = setor?.SessionWhatsWeb;
            this.TelefoneWhatsWeb = setor?.TelefoneWhatsWeb;
            this.sessionKeyWhatsWeb = setor?.SessionKeyWhatsWeb;
            this.InclusaoFormatada = setor?.InclusaoFormatada;
            this.AlteracaoFormatada = setor?.AlteracaoFormatada;
            this.App = setor?.App;
            this.AppKey = setor?.AppKey;
            this.NumeroBM = setor?.NumeroBM;
            this.EmailSAC = setor?.EmailSAC;
            this.Status = null
            
            this.Provedor = setor?.Provedor;
            this.SMSHabilitado = setor?.SMSHabilitado;
            this.ProvedorSMS = setor?.ProvedorSMS;
            this.UsuarioSMSUniPix = setor?.UsuarioSMSUniPix;
            this.SenhaSMSUniPix = setor?.SenhaSMSUniPix;
        }
    }
    export default {
        name: 'SetoresPage',

        components: {
            Breadcrumb,
            HeaderFuncoesLista,
            TabelaSetores,
            SetorForm,
            SetorEmailForm
            
        },

        data: function () {
            return {

                itemsBreadcrumb: [
                    {
                        id: '2',
                        classe: 'fa fa-cogs',
                        texto: 'Cadastros Básicos',
                        link: '/cadastros-basicos'
                    },

                    {
                        id: '3',
                        classe: 'fa fa-users-cog',
                        texto: 'Setores',
                        link: '/setores'
                    }
                ],
                colunasSetor: [
                    { key: 'Nome', sortable: true },
                    { key: 'Inclusao', sortable: true },
                    { key: 'Alteracao', sortable: true },
                    { key: 'TelefoneWhatsWeb', label: 'Telefone', sortable: true },
                    { key: 'Status', sortable: true },
                    { key: 'botoes', label: 'Ação', class: 'text-center' },
                ],
                textoPesquisa: '',
                listagemSetores: null,
                listagemSetoresComWhatsWeb: null,
                isStatusConnect: false,
                // dadosUser: this.$store.getters['empresasLicencas/listaEmpresas']//  this.$store.getters['authentication/authDadosUsuario']
            }
        },

        computed: {
            ...mapGetters({
            //    listaSetores: 'setores/listaSetores',
                listaUsuarioSetores: 'usuarioSetor/listaUsuarioSetores',
                listaIdAtendimentos: 'atendimento/listaIdAtendimentos'
            }),
            getColunasSetor() {

                if (this.listaSetores) {
                    return this.listaSetores.some(value => value.HabilitarWhatsWeb == 'Sim') ? ([{ key: 'Nome', sortable: true },
                    { key: 'Inclusao', sortable: true },
                    { key: 'Alteracao', sortable: true },
                    { key: 'TelefoneWhatsWeb', label: 'Telefone', sortable: true },
                    { key: 'Status', sortable: true },
                    { key: 'botoes', label: 'Ação', class: 'text-center' }]) : this.colunasSetor
                }
                return this.colunasSetor
            },
            setores: function () {
                if (this.listaSetores) {
                    if (this.textoPesquisa) {
                        return this.listagemSetores.filter(obj =>
                            obj.Nome ? obj.Nome.toLowerCase().includes(this.textoPesquisa) : '' ||
                                obj.Altarecao ? obj.Altarecao.toLowerCase().includes(this.textoPesquisa) : '' ||
                                    obj.Inclusao ? obj.Inclusao.toLowerCase().includes(this.textoPesquisa) : ''

                        )

                    }

                    return this.listagemSetores;
                }
                return [];
            },
        },

        created() {
            this.buscarSetores();

            if (!this.listaUsuarioSetores) {
                this.buscaUsuarioSetores()
            }

            this.buscaAtendimentosAtivoAndPendentes()
        },

        methods: {
            ...mapActions({
                buscarListaSetores: 'setores/buscaSetores',
                buscaUsuarioSetores: 'usuarioSetor/buscaUsuarioSetores',
                buscaAtendimentosAtivoAndPendentes: 'atendimento/buscaAtendimentosAtivoAndPendentes'
            }),
            recarregar: function () {
                this.buscarSetores();
            },
            buscarSetores() {

                $("#caixa_carregamento").show();
                axios.get('/api/setor')
                    .then((response) => {
                        this.listagemSetores = response.data.map(item => new Setor(item));
                        if (this.listagemSetores.some(value => value.HabilitarWhatsWeb == 'Sim')) {
                            
                            this.listagemSetores.forEach(async (value, index) => {
                               
                                if (value.HabilitarWhatsWeb == "Sim") {
                                    var response = await axios.post('api/setor/VerifyWhatsWebConnected', value)
                            
                                    this.listagemSetores[index].Status = response.data ? response.data : 0
                                }
                            })
                        }
                        $("#caixa_carregamento").hide();

                    }, (error) => {
                        console.log(error);
                        $("#caixa_carregamento").hide();
                    });
            },

            setSetor: function (setor) {
                this.$refs.formularioSetor.showModal(setor ? setor : new Setor);
            },
            setConfigEmail: function (setor) {
                this.$refs.formularioEmailSetor.showModal(setor ? setor : new Setor);
            },

            removerSetor: function (data, msg) {
                this.$bvModal.msgBoxConfirm(msg, {
                    title: 'Atenção',
                    okTitle: 'Confirmar',
                    cancelTitle: 'Cancelar',
                    okVariant: 'success',
                    cancelVariant: 'white',
                    centered: true
                }).then(confirmado => {
                    if (confirmado) {
                        axios.delete('/api/setor/' + data.Id)
                            .then((response) => {
                                this.buscarSetores();
                                $("#caixa_carregamento").hide();
                            }, (error) => {
                                console.log(error);
                                $("#caixa_carregamento").hide();
                            });
                        this.$store.dispatch(`setores/remover`, data)
                    }
                })
            },
            pesquisarSetor: function (texto) {
                this.textoPesquisa = texto.toLowerCase();
            },
        },
    }
</script>

<style scoped>
    .setores-page-container-externo-tabela {
        padding: 10px;
    }

    .setores-page-container-interno-tabela {
        padding: 10px;
        background-color: var(--cinza-3);
    }

    .setores-page-tabela {
        background-color: #fff;
        height: calc(100vh - 220px);
    }

    .contador-setores {
        color: var(--cinza-5);
        position: relative;
        top: 5px;
    }

    .button-hover-padrao {
        border-color: #007bff;
        color: #007bff;
    }

        .button-hover-padrao:hover {
            background-color: var(--cinza-5) !important;
            border-color: var(--cinza-5) !important;
            color: white;
        }

        .button-hover-padrao:active {
            background-color: rgb(168, 168, 168) !important;
            border-color: rgb(168, 168, 168) !important;
            color: white;
        }
</style>